import React from "react";
import { Page } from "@/components/Page";

export default function NotFoundPage() {
  return (
    <Page>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Page>
  );
}
